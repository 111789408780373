<template>
  <div
    class="custom-margin"
    style="
      height: 48vh;
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      left: auto;
      top: auto;
    "
  >
    <div class="" style="height: fit-content">
      <h1 class="d-block text-center">Solicitação de personalização</h1>
      <p>
        O prazo para solicitação de personalização de unidade do B. Great Bosque
        dos Buritis foi encerrado.
        <br />
        Em breve entraremos em contato para dar continuidade aos pedidos por
        aqui anteriormente solicitados.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "personalization-finished",
};
</script>

<style scoped>
.custom-margin {
  margin-top: 80px;
}
</style>
